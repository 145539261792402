<template>
  <div class="auth-container">
    <div class="w-100 h-100">
      <div class="row m-2 h-100 justify-content-center">
        <div class="col-10 col-md-5 col-lg-3 align-self-center">
          <div
            class="w-100 d-flex flex-column justify-content-center align-items-center text-white"
          >
            <p class="m-0 font-large font-weight-bolder">Reset password</p>
            <div>
              <!-- <p class="m-0 mt-2 font-smaller">
                We heard that you lost your password. Sorry about that!
              </p> -->
              <p class="m-0 mt-2 font-smaller">Enter your new password</p>
            </div>
          </div>
          <div class="w-100 mt-2">
            <form id="auth-form" @submit.prevent="submit">
              <div class="form-group mb-2">
                <input
                  type="password"
                  class="form-control"
                  v-model="models.password"
                  :class="{ 'border-danger': errors.password }"
                  id="auth-password"
                  placeholder="Password"
                />
                <label class="form-control-label" for="email">Password</label>
              </div>
              <div class="form-group mb-2">
                <input
                  type="password"
                  class="form-control"
                  v-model="models.confirmpassword"
                  :class="{ 'border-danger': errors.confirmpassword }"
                  id="auth-confirm-password"
                  placeholder="Confirm Password"
                />
                <label class="form-control-label" for="email">Confirm</label>
              </div>
              <button type="submit" class="btn w-100">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="isProcessing"
                ></span>
                <span v-if="!isProcessing">Reset Password</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isProcessing: false,
      models: {
        password: null,
        confirmpassword: null
      },

      errors: {
        password: null,
        confirmpassword: null
      }
    };
  },
  methods: {
    submit() {
      this.isProcessing = true;
      const hasError = this.verifyForm();
      if (hasError) {
        console.log("has error");
        this.isProcessing = false;
      } else {
        let code = this.$route.query.code;
        API.post("auth/reset-password", {
          code: code, // code contained in the reset link of step 3.
          password: this.models.password,
          passwordConfirmation: this.models.confirmpassword
        })
          .then(res => {
            console.log(res.data);
            this.isProcessing = false;
            // if(res.data){
            Swal.fire({
              title: "Success",
              text:
                "Your password successfully reset, you can login with your new password",
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK"
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push({
                  name: "Auth"
                });
              }
            });
            // }
          })
          .catch(err => {
            
            let resErr = err.response
            
            let msg =  resErr.data.message && resErr.data.message.length ? resErr.data.message[0] : 'Unable to reset password'
            
            this.isProcessing = false;
            Swal.fire(
              "",
              msg.messages[0].message? msg.messages[0].message : 'Unable to reset password',
              "error"
            );

          });
      }
    },
    verifyForm() {
      this.loginError = false;
      for (let index = 0; index < Object.keys(this.models).length; index++) {
        const key = Object.keys(this.models)[index];
        const model = this.models[key];

        if (!model) {
          this.errors[key] = true;
          this.loginError = true;
        } else {
          this.errors[key] = false;
        }
      }

      return this.loginError;
    }
  }
};
</script>